import { combineReducers } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import { FacilitiesReducer } from 'store/slices/Facilities';
import { AnalyticsReducer } from './Analytics/';
import { AnalyticsFacilityReducer } from './Analytics/Facility/AnalyticsFacilitySlice';
import { BaseLinksReducer } from './Auth/Links/BaseLinksSlice';
import { AnalyticsApi } from './_Api/Analytics';
import { AuthApi } from './_Api/Auth';
import { FacilitiesApi } from './_Api/Facilities';
import { AccessTerminalApi } from './_Api/Facilities/AccessTerminal';
import { CamerasApi } from './_Api/Facilities/Cameras';
import { LanesApi } from './_Api/Facilities/Lanes';
import { RelaysApi } from './_Api/Facilities/Relays';
import MatchingApi from './_Api/Matching';
import { OperatorsApi } from './_Api/Operators';
import { VehicleGroupsApi } from './_Api/VehicleGroups';
import { LogoutReducer } from './slices/Logout';
import { SearchReducer } from './slices/Search';
import { VehicleGroupReducer } from './slices/VehicleGroups';
import { UsersApi } from './_Api/Users';
import { SearchApi } from './_Api/Search';
import { ParkingSessionsApi } from './_Api/ParkingSessions';
import { KiosksApi } from './_Api/Facilities/Kiosks';
import { SubscriptionsApi } from './_Api/Subscriptions';
import { ParkingTerminalApi } from './_Api/Facilities/ParkingTerminal';
import { CarParksReducer } from './slices/CarParks';

const rootReducer = combineReducers({
  baseLinks: BaseLinksReducer,
  carParks: CarParksReducer,
  facility: FacilitiesReducer,
  analytics: AnalyticsReducer,
  facilityAnalytics: AnalyticsFacilityReducer,
  vehicleGroups: VehicleGroupReducer,
  Search: SearchReducer,
  Logout: LogoutReducer,
  [AuthApi.reducerPath]: AuthApi.reducer,
  [AnalyticsApi.reducerPath]: AnalyticsApi.reducer,
  [UsersApi.reducerPath]: UsersApi.reducer,
  [SearchApi.reducerPath]: SearchApi.reducer,
  [VehicleGroupsApi.reducerPath]: VehicleGroupsApi.reducer,
  [FacilitiesApi.reducerPath]: FacilitiesApi.reducer,
  [OperatorsApi.reducerPath]: OperatorsApi.reducer,
  [CamerasApi.reducerPath]: CamerasApi.reducer,
  [RelaysApi.reducerPath]: RelaysApi.reducer,
  [LanesApi.reducerPath]: LanesApi.reducer,
  [AccessTerminalApi.reducerPath]: AccessTerminalApi.reducer,
  [ParkingSessionsApi.reducerPath]: ParkingSessionsApi.reducer,
  [MatchingApi.reducerPath]: MatchingApi.reducer,
  [KiosksApi.reducerPath]: KiosksApi.reducer,
  [SubscriptionsApi.reducerPath]: SubscriptionsApi.reducer,
  [ParkingTerminalApi.reducerPath]: ParkingTerminalApi.reducer,
});

export const middleware = [
  thunkMiddleware,
  AuthApi.middleware,
  AnalyticsApi.middleware,
  UsersApi.middleware,
  SearchApi.middleware,
  VehicleGroupsApi.middleware,
  FacilitiesApi.middleware,
  OperatorsApi.middleware,
  CamerasApi.middleware,
  RelaysApi.middleware,
  ParkingSessionsApi.middleware,
  LanesApi.middleware,
  AccessTerminalApi.middleware,
  MatchingApi.middleware,
  KiosksApi.middleware,
  SubscriptionsApi.middleware,
  ParkingTerminalApi.middleware,
];

export default rootReducer;
