import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CarPark } from 'models/CarParks';

export type CarParkMapShape = {
  [key: string]: CarPark;
} | null;

interface CarParksSliceState {
  map: CarParkMapShape;
  list: CarPark[];
}

const initialState: CarParksSliceState = {
  map: null,
  list: [],
};

const slice = createSlice({
  name: 'carParks',
  initialState,
  reducers: {
    setCarParksMap(
      state: CarParksSliceState,
      action: PayloadAction<CarParkMapShape>,
    ) {
      state.map = action.payload;
    },
    setCarParksList(
      state: CarParksSliceState,
      action: PayloadAction<CarPark[]>,
    ) {
      state.list = action.payload;
    },
  },
});

export const { setCarParksMap, setCarParksList } = slice.actions;

export const CarParksReducer = slice.reducer;
