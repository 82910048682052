import { FC, useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress } from '@mui/material';

import './SuspenseLoader.scss';

type Props = {
  alignItems?: string;
};

const SuspenseLoader: FC<Props> = ({ alignItems = 'center' }): JSX.Element => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      className="suspense-loader"
      display="flex"
      alignItems={alignItems}
      justifyContent="center"
    >
      <CircularProgress size={50} disableShrink thickness={3} />
    </Box>
  );
};

export default SuspenseLoader;
